import { DatePipe } from '@angular/common'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { HasAnyAuthorityDirective } from './auth/has-any-authority.directive'
import { OAuth2Service } from './auth/oauth2.service'
import { StateStorageService } from './auth/state-storage.service'
import { CustomBadgeComponent } from './badge/custom-badge.component'
import { NotificationConfig } from './config/notification/notification.config'
import { OAuth2Config } from './config/oauth2/oauth2.config'
import { CopyToClipboardComponent } from './copy-to-clipboard/copy-to-clipboard.component'
import { FileDropDirective } from './file-drop/file-drop.directive'
import { PendingChangesGuard } from './guard/pending-changes.guard'
import { ImageManagerComponent } from './image-manager/image-manager.component'
import { LanguageChoiceService } from './language/language-choice.service'
import { ActiveMenuDirective } from './navbar/active-menu.directive'
import { NavbarComponent } from './navbar/navbar.component'
import { DynamicCollapsiblePipe } from './pipe/dynamic-collapsible.pipe'
import { FileSizePipe } from './pipe/file-size.pipe'
import { PreviewPipe } from './pipe/preview.pipe'
import { ReplaceDiffMarkersPipe } from './pipe/replace-diff-markers.pipe'
import { ShareLinkComponent } from './share-link/share-link.component'
import { JhipsterSharedCommonModule } from './shared-common.module'
import { TitleService } from './title/title.service'
import { DeleteCustomTagsPipe } from './pipe/delete-custom-tags.pipe'
import { PreviewMaterialCategoryIconPipe } from './pipe/preview-material-category-icon.pipe'

import { DropdownMaterialCategoryComponent } from './dropdown/dropdown-material-category/dropdown-material-category.component'
import { DropdownMaterialCategoryItemComponent } from './dropdown/dropdown-material-category/dropdown-material-category-item/dropdown-material-category-item.component'
import { PreviewMaterialCategoryIconAlonePipe } from './pipe/preview-material-category-icon-alone.pipe'
import { AppVersionComponent } from './components'

@NgModule({
    imports: [
        JhipsterSharedCommonModule,
        RouterModule,
    ],
    declarations: [
        ImageManagerComponent,
        HasAnyAuthorityDirective,
        FileSizePipe,
        PreviewPipe,
        ReplaceDiffMarkersPipe,
        FileDropDirective,
        ActiveMenuDirective,
        NavbarComponent,
        ShareLinkComponent,
        CopyToClipboardComponent,
        CustomBadgeComponent,
        DynamicCollapsiblePipe,
        DeleteCustomTagsPipe,
        PreviewMaterialCategoryIconPipe,
        PreviewMaterialCategoryIconAlonePipe,
        DropdownMaterialCategoryComponent,
        DropdownMaterialCategoryItemComponent,
        AppVersionComponent
    ],
    providers: [
        StateStorageService,
        DatePipe,
        PendingChangesGuard,
        OAuth2Config,
        NotificationConfig,
        LanguageChoiceService,
        OAuth2Service,
        TitleService
    ],
    exports: [
        JhipsterSharedCommonModule,
        ImageManagerComponent,
        HasAnyAuthorityDirective,
        DatePipe,
        FileSizePipe,
        PreviewPipe,
        ReplaceDiffMarkersPipe,
        FileDropDirective,
        ActiveMenuDirective,
        NavbarComponent,
        ShareLinkComponent,
        CopyToClipboardComponent,
        CustomBadgeComponent,
        DynamicCollapsiblePipe,
        DeleteCustomTagsPipe,
        PreviewMaterialCategoryIconPipe,
        PreviewMaterialCategoryIconAlonePipe,
        DropdownMaterialCategoryComponent,
        AppVersionComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class JhipsterSharedModule {
}
